.resources-section {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: black;
  color: white;
  padding-top: 20px;
  padding-bottom: 6rem;
  justify-content: center;
  margin: auto;
  align-items: center !important;
}
.resources-text {
  width: 608px;
  justify-self: center;
}
.home-resources h2 {
  margin: 0;
}
.home-resources p {
  color: white;
  justify-self: center;
}
.resources-container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
  justify-content: center;
  gap: 70px;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .resources-container {
    gap: 50px;
    flex-direction: column;
  }
}
@media screen and (min-width: 1200px) {
  .resources-container {
    gap: 50px;
    flex-direction: row;
  }
}
